import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'

const CMP = props => (
  <Helmet>
    <script type="application/javascript">
      {`
      
      (function() {

    if (location.href.match(/https:\\/\\/www\\.basaas\\.com\\/changelog/)) { return; }
 
        var host = window.location.hostname;
        var element = document.createElement('script');
        var firstScript = document.getElementsByTagName('script')[0];
        var url = 'https://quantcast.mgr.consensu.org'
            .concat('/choice/', 'p4dFnF67SJUqP', '/', host, '/choice.js')
        var uspTries = 0;
        var uspTriesLimit = 3;
        element.async = true;
        element.type = 'text/javascript';
        element.src = url;

        firstScript.parentNode.insertBefore(element, firstScript);

        function makeStub() {
            var TCF_LOCATOR_NAME = '__tcfapiLocator';
            var queue = [];
            var win = window;
            var cmpFrame;

            function addFrame() {
                var doc = win.document;
                var otherCMP = !!(win.frames[TCF_LOCATOR_NAME]);

                if (!otherCMP) {
                    if (doc.body) {
                        var iframe = doc.createElement('iframe');

                        iframe.style.cssText = 'display:none';
                        iframe.name = TCF_LOCATOR_NAME;
                        doc.body.appendChild(iframe);
                    } else {
                        setTimeout(addFrame, 5);
                    }
                }
                return !otherCMP;
            }

            function tcfAPIHandler() {
                var gdprApplies;
                var args = arguments;

                if (!args.length) {
                    return queue;
                } else if (args[0] === 'setGdprApplies') {
                    if (
                        args.length > 3 &&
                        args[2] === 2 &&
                        typeof args[3] === 'boolean'
                    ) {
                        gdprApplies = args[3];
                        if (typeof args[2] === 'function') {
                            args[2]('set', true);
                        }
                    }
                } else if (args[0] === 'ping') {
                    var retr = {
                        gdprApplies: gdprApplies,
                        cmpLoaded: false,
                        cmpStatus: 'stub'
                    };

                    if (typeof args[2] === 'function') {
                        args[2](retr);
                    }
                } else {
                    queue.push(args);
                }
            }

            function postMessageEventHandler(event) {
                var msgIsString = typeof event.data === 'string';
                var json = {};

                try {
                    if (msgIsString) {
                        json = JSON.parse(event.data);
                    } else {
                        json = event.data;
                    }
                } catch (ignore) {}

                var payload = json.__tcfapiCall;

                if (payload) {
                    window.__tcfapi(
                        payload.command,
                        payload.version,
                        function(retValue, success) {
                            var returnMsg = {
                                __tcfapiReturn: {
                                    returnValue: retValue,
                                    success: success,
                                    callId: payload.callId
                                }
                            };
                            if (msgIsString) {
                                returnMsg = JSON.stringify(returnMsg);
                            }
                            event.source.postMessage(returnMsg, '*');
                        },
                        payload.parameter
                    );
                }
            }

            while (win) {
                try {
                    if (win.frames[TCF_LOCATOR_NAME]) {
                        cmpFrame = win;
                        break;
                    }
                } catch (ignore) {}

                if (win === window.top) {
                    break;
                }
                win = win.parent;
            }
            if (!cmpFrame) {
                addFrame();
                win.__tcfapi = tcfAPIHandler;
                win.addEventListener('message', postMessageEventHandler, false);
            }
        };

        makeStub();

        var uspStubFunction = function() {
            var arg = arguments;
            if (typeof window.__uspapi !== uspStubFunction) {
                setTimeout(function() {
                    if (typeof window.__uspapi !== 'undefined') {
                        window.__uspapi.apply(window.__uspapi, arg);
                    }
                }, 500);
            }
        };

        var checkIfUspIsReady = function() {
            uspTries++;
            if (window.__uspapi === uspStubFunction && uspTries < uspTriesLimit) {
                console.warn('USP is not accessible');
            } else {
                clearInterval(uspInterval);
            }
        };

        if (typeof window.__uspapi === 'undefined') {
            window.__uspapi = uspStubFunction;
            var uspInterval = setInterval(checkIfUspIsReady, 6000);
        }

        window.basaas = window.basaas || {};
        basaas.global = window.basaas.global || {};
        basaas.global.cmp = basaas.global.cmp || {
            vendors: {
                hotjar: 1,
                googleAnalytics: 2,
            },
            consentedVendors: {},
        };

        function cmpLoadConsentedVendors() {
            __tcfapi('getNonIABVendorConsents',2, function(consent, success) {
                if (success && consent.nonIabVendorConsents) {
                    if (consent.nonIabVendorConsents[basaas.global.cmp.vendors.googleAnalytics] && !basaas.global.cmp.consentedVendors.googleAnalytics) {
                        basaas.global.cmp.vendors.googleAnalytics = 1;
                        
                        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer','GTM-TXLMHHZ');

                    }

                    if (consent.nonIabVendorConsents[basaas.global.cmp.vendors.hotjar] && !basaas.global.cmp.consentedVendors.hotjar) {
                        basaas.global.cmp.vendors.hotjar = 1;
                        (function(h,o,t,j,a,r){
                            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                            h._hjSettings={hjid:1061892,hjsv:6};
                            a=o.getElementsByTagName('head')[0];
                            r=o.createElement('script');r.async=1;
                            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                            a.appendChild(r);
                        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                    }
                }
            });
        }

        __tcfapi('addEventListener', 2, function(tcData, success) {
            if (success && (tcData.eventStatus === 'useractioncomplete' || tcData.eventStatus === 'tcloaded')) {
                cmpLoadConsentedVendors();
            }
        });

    })();
      `}
    </script>
  </Helmet>
)

export default CMP
