import { Link } from 'gatsby'
import React, { useState } from 'react'
import { navContainer, bgOverlay } from './index.module.scss'
import Logo from '../../assets/basaas-logo.svg'

const Nav = () => {
  const [menuVisible, setMenuVisible] = useState(false)

  return (
    <React.Fragment>
      <header className={navContainer}>
        <div className="inner-wrapper">
          <Link to="/" className="brand">
            <Logo className="logo" />
          </Link>
          <div className="nav-item-container">
            <button className={`menu-toggle ${menuVisible ? 'active' : ''}`} onClick={() => setMenuVisible(!menuVisible)}>
              <svg className="menu-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="7" y="11" width="10" height="2" fill="#002E4D" />
                <rect x="7" y="15" width="10" height="2" fill="#002E4D" />
                <rect x="7" y="7" width="10" height="2" fill="#002E4D" />
              </svg>
              Menu
            </button>
            <div className={`menu-inner-wrapper ${menuVisible ? 'active' : ''}`}>
              {/* 
            Inline Links for One Pager
            <Link activeClass="active" to="#section1" spy={true} smooth={true} offset={-70} duration={500}>
              Section 1
            </Link> */}

              <div className="primary">
                <div className="divider" />
                <Link className="nav-item" to="/">
                  Home
                </Link>
                {/* <Link className="nav-item" to="/workplace">
                  Workplace
                </Link>
                <Link className="nav-item" to="/tasks">
                  Tasks
                </Link> */}
                <Link className="nav-item" to="/pricing">
                  Pricing
                </Link>
              </div>

              <div className="secondary">
                <Link className="nav-item" to="/demo">
                  Demo
                </Link>
                <div className="divider" />
                <a className="nav-item" href="https://workplace.basaas.com?utm_source=basaas-com&utm_medium=nav&utm_campaign=login" target="_blank" rel="noreferrer">
                  Login
                </a>
                <a className="nav-item cta" href="https://workplace.basaas.com/register/?utm_source=basaas-com&utm_medium=nav&utm_campaign=signup" target="_blank" rel="noreferrer">
                  Sign up
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
      {menuVisible && <div className={bgOverlay} />}
    </React.Fragment>
  )
}

export default Nav
