import { Link } from 'gatsby'
import React from 'react'

import { footerContainer } from './index.module.scss'

const togglePM = e => {
  e.preventDefault()
  // eslint-disable-next-line no-underscore-dangle
  window.__tcfapi('displayConsentUi', 2, () => {})
}

const Footer = () => (
  <footer className={footerContainer}>
    <div className="inner-wrapper">
      <div className="column">
        <div className="category">
          <span className="title">Company</span>
          <Link className="link" to="/blog">
            Blog
          </Link>
          <Link className="link" to="/about-us">
            About Us
          </Link>
          <Link className="link" to="/terms">
            Terms of Service
          </Link>
          <Link className="link" to="/privacy-policy">
            Privacy Policy
          </Link>
          <Link className="link" to="/#" onClick={togglePM}>
            Privacy Manager
          </Link>
          <Link className="link" to="/imprint">
            Imprint
          </Link>
          {/* <Link className="link" to="/">
            Basaas Marketplace
          </Link> */}
        </div>
      </div>

      <div className="column">
        <div className="category">
          <span className="title">Product</span>

          <Link className="link" to="/browser-extension">
            Get Browser Extension
          </Link>
          {/* <Link className="link" to="/basaas-app">
            Get Basaas App
          </Link>
          <Link className="link" to="/features">
            All Features
          </Link>
          <Link className="link" to="/use-cases">
            Use Cases
          </Link>
           */}
          <Link className="link" to="/enterprise">
            Basaas for Enterprise
          </Link>
          <Link className="link" to="/security">
            Security & Data Privacy
          </Link>
        </div>
      </div>

      <div className="column">
        <div className="category">
          <span className="title">Partner</span>
          <Link className="link" to="/partner">
            Partner Program
          </Link>
        </div>
        <div className="category">
          <span className="title">Vendor</span>
          <a className="link" href="https://store.basaas.com/account-service/login" target="_blank" rel="noreferrer">
            Vendor Login
          </a>
          {/* <Link className="link" to="/">
            Partner Marketplaces
          </Link>
          <Link className="link" to="/">
            Basaas for Vendors
          </Link> */}
        </div>
      </div>

      <div className="column">
        <div className="category">
          <span className="title">Service & Support</span>
          <a className="link" href="https://help.basaas.com/hc/en-us" target="_blank" rel="noreferrer">
            Help Center
          </a>
          <Link className="link" to="/demo">
            Book a Demo
          </Link>
          <Link className="link" to="/contact">
            Contact
          </Link>
          {/* <Link className="link" to="/">
            FAQ
          </Link> */}
        </div>
        <div className="category">
          <span className="title">Follow us</span>
          <div className="social-container">
            <a className="link social" href="https://www.linkedin.com/company/basaas" target="_blank" rel="noreferrer">
              <svg className="linkedin" version="1.1" xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
                <path d="M192 192h88.553v45.391h1.266c12.32-22.097 42.479-45.391 87.421-45.391 93.473 0 110.76 58.188 110.76 133.867v154.133h-92.305v-136.639c0-32.592-0.667-74.513-48.014-74.513-48.074 0-55.41 35.493-55.41 72.146v139.006h-92.271v-288z"></path>
                <path d="M32 192h96v288h-96v-288z"></path>
                <path d="M128 112c0 26.51-21.49 48-48 48s-48-21.49-48-48c0-26.51 21.49-48 48-48s48 21.49 48 48z"></path>
              </svg>
            </a>
            <a className="link social" href="https://www.facebook.com/com.basaas" target="_blank" rel="noreferrer">
              <svg className="facebook" version="1.1" xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
                <path d="M304 96h80v-96h-80c-61.757 0-112 50.243-112 112v48h-64v96h64v256h96v-256h80l16-96h-96v-48c0-8.673 7.327-16 16-16z"></path>
              </svg>
            </a>
            <a className="link social" href="https://twitter.com/basaas_com" target="_blank" rel="noreferrer">
              <svg className="twitter" version="1.1" xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
                <path d="M512 113.2c-18.8 8.4-39.1 14-60.3 16.5 21.7-13 38.3-33.6 46.2-58.1-20.3 12-42.8 20.8-66.7 25.5-19.2-20.4-46.5-33.1-76.7-33.1-58 0-105 47-105 105 0 8.2 0.9 16.2 2.7 23.9-87.3-4.4-164.7-46.2-216.5-109.8-9 15.5-14.2 33.6-14.2 52.8 0 36.4 18.5 68.6 46.7 87.4-17.2-0.5-33.4-5.3-47.6-13.1 0 0.4 0 0.9 0 1.3 0 50.9 36.2 93.4 84.3 103-8.8 2.4-18.1 3.7-27.7 3.7-6.8 0-13.3-0.7-19.8-1.9 13.4 41.7 52.2 72.1 98.1 73-36 28.2-81.2 45-130.5 45-8.5 0-16.8-0.5-25.1-1.5 46.6 29.9 101.8 47.2 161.1 47.2 193.2 0 298.9-160.1 298.9-298.9 0-4.6-0.1-9.1-0.3-13.6 20.5-14.7 38.3-33.2 52.4-54.3z"></path>
              </svg>
            </a>
            <a className="link social" href="https://www.instagram.com/basaas.workplace/" target="_blank" rel="noreferrer">
              <svg className="instagram" version="1.1" xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
                <path d="M256 46.1c68.4 0 76.5 0.3 103.4 1.5 25 1.1 38.5 5.3 47.5 8.8 11.9 4.6 20.5 10.2 29.4 19.1 9 9 14.5 17.5 19.2 29.4 3.5 9 7.7 22.6 8.8 47.5 1.2 27 1.5 35.1 1.5 103.4s-0.3 76.5-1.5 103.4c-1.1 25-5.3 38.5-8.8 47.5-4.6 11.9-10.2 20.5-19.1 29.4-9 9-17.5 14.5-29.4 19.2-9 3.5-22.6 7.7-47.5 8.8-27 1.2-35.1 1.5-103.4 1.5s-76.5-0.3-103.4-1.5c-25-1.1-38.5-5.3-47.5-8.8-11.9-4.6-20.5-10.2-29.4-19.1-9-9-14.5-17.5-19.2-29.4-3.5-9-7.7-22.6-8.8-47.5-1.2-27-1.5-35.1-1.5-103.4s0.3-76.5 1.5-103.4c1.1-25 5.3-38.5 8.8-47.5 4.6-11.9 10.2-20.5 19.1-29.4 9-9 17.5-14.5 29.4-19.2 9-3.5 22.6-7.7 47.5-8.8 26.9-1.2 35-1.5 103.4-1.5zM256 0c-69.5 0-78.2 0.3-105.5 1.5-27.2 1.2-45.9 5.6-62.1 11.9-16.9 6.6-31.2 15.3-45.4 29.6-14.3 14.2-23 28.5-29.6 45.3-6.3 16.3-10.7 34.9-11.9 62.1-1.2 27.4-1.5 36.1-1.5 105.6s0.3 78.2 1.5 105.5c1.2 27.2 5.6 45.9 11.9 62.1 6.6 16.9 15.3 31.2 29.6 45.4 14.2 14.2 28.5 23 45.3 29.5 16.3 6.3 34.9 10.7 62.1 11.9 27.3 1.2 36 1.5 105.5 1.5s78.2-0.3 105.5-1.5c27.2-1.2 45.9-5.6 62.1-11.9 16.8-6.5 31.1-15.3 45.3-29.5s23-28.5 29.5-45.3c6.3-16.3 10.7-34.9 11.9-62.1 1.2-27.3 1.5-36 1.5-105.5s-0.3-78.2-1.5-105.5c-1.2-27.2-5.6-45.9-11.9-62.1-6.3-17-15-31.3-29.3-45.5-14.2-14.2-28.5-23-45.3-29.5-16.3-6.3-34.9-10.7-62.1-11.9-27.4-1.3-36.1-1.6-105.6-1.6v0z"></path>
                <path d="M256 124.5c-72.6 0-131.5 58.9-131.5 131.5s58.9 131.5 131.5 131.5 131.5-58.9 131.5-131.5c0-72.6-58.9-131.5-131.5-131.5zM256 341.3c-47.1 0-85.3-38.2-85.3-85.3s38.2-85.3 85.3-85.3c47.1 0 85.3 38.2 85.3 85.3s-38.2 85.3-85.3 85.3z"></path>
                <path d="M423.4 119.3c0 16.955-13.745 30.7-30.7 30.7s-30.7-13.745-30.7-30.7c0-16.955 13.745-30.7 30.7-30.7s30.7 13.745 30.7 30.7z"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className="inner-wrapper meta">
      <span className="made-with">
        Made with <span className="heart">♥︎</span> in Berlin + Cologne
      </span>
    </div>
  </footer>
)

// Footer.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Footer.defaultProps = {
//   siteTitle: ``,
// }

export default Footer
