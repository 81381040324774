// extracted by mini-css-extract-plugin
export const footerContainer = "index-module__footerContainer--3tTlu";
export const innerWrapper = "inner-wrapper";
export const meta = "meta";
export const column = "column";
export const category = "category";
export const title = "title";
export const link = "link";
export const socialContainer = "social-container";
export const social = "social";
export const madeWith = "made-with";
export const heart = "heart";