import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

/* Global Font Import https://github.com/fontsource/fontsource/tree/master/packages */
import '@fontsource/open-sans/300.css'
import '@fontsource/open-sans/400.css'
import '@fontsource/open-sans/600.css'
import '@fontsource/open-sans/700.css'
import '@fontsource/open-sans/800.css'

import Nav from '../../components/nav'
import Footer from '../../components/footer'
import { mainContainer } from './index.module.scss'
import CMP from '../../components/cmp'
import Container from '../../components/container'

const MainContainer = (props, { children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <CMP />
      <Nav siteTitle={data.site.siteMetadata.title || 'Title'} />
      <main className={`${mainContainer} ${props.fixedWidth ? 'fixed-width' : ''}`}>{props.children}</main>
      <Container>
        <Footer />
      </Container>
    </>
  )
}

MainContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MainContainer
